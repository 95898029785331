
import { Component, Prop, Vue } from 'vue-property-decorator'
import CardHeader from '~/components/shared/card/CardHeader.vue'
import ArticleCard from '~/components/shared/card/ArticleCard.vue'
import BannerContent from '~/components/shared/BannerContent.vue'
import CompanySquareCard from '~/components/shared/card/CompanySquareCard.vue'

@Component({
  components: {
    CardHeader,
    ArticleCard,
    BannerContent,
    CompanySquareCard
  }
})
export default class CarouselContents extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  private items!: any[]

  @Prop({
    type: String,
    required: true
  })
  private type!: string

  @Prop({
    type: String,
    required: false
  })
  private used!: string

  private carouselSetting: any = {
    slidesPerView: 1.2,
    spaceBetween: 24,
    loop: false,
    speed: 1000,
    touchRatio: 1,
    touchReleaseOnEdges: false,
    watchSlidesVisibility: true,
    observeParents: true,
    observer: true,
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next'
    },
    breakpoints: {
      767: {
        slidesPerView: 'auto',
        spaceBetween: 32
      }
    }
  }

  private mounted() {
    this.$nextTick(() => {
      const swiperElements = this.$el.querySelectorAll('.swiper-container')
      const swiperContent = (this as any).$refs.swiperContent.$swiper
      if (this.$refs.swiper) swiperContent.update()
      swiperElements.forEach((swiperEl) => {
        swiperEl.removeEventListener('touchstart', this.handleTouchEvent)
        swiperEl.removeEventListener('touchmove', this.handleTouchEvent)
        swiperEl.removeEventListener('touchend', this.handleTouchEvent)
        swiperEl.addEventListener('touchstart', this.handleTouchEvent)
        swiperEl.addEventListener('touchmove', this.handleTouchEvent)
        swiperEl.addEventListener('touchend', this.handleTouchEvent)
      })
    })
  }

  private handleTouchEvent(event: TouchEvent) {
    event.stopPropagation()
  }
}
